import { Component, Mixins } from "vue-property-decorator";

import StatisticMixin from "../../common/mixins/statistic.mixin";

@Component
export default class MobileStatistic extends Mixins(StatisticMixin) {
  private get screenTitle() {
    if (this.isStatisticByType) {
      return `${this.$vuetify.lang.t(
        "$vuetify.dashboard.sidebar.statistic"
      )} (${this.$vuetify.lang.t(
        "$vuetify.dashboard.sidebar.statistic_by_type"
      )})`;
    }

    return `${this.$vuetify.lang.t(
      "$vuetify.dashboard.sidebar.statistic"
    )} (${this.$vuetify.lang.t(
      "$vuetify.dashboard.sidebar.statistic_by_status"
    )})`;
  }

  private get topBarSuccessButton() {
    return {
      title: this.$vuetify.lang.t("$vuetify.dashboard.search"),
      loading: this.loading,
      disabled: this.disabledSearch,
      listeners: {
        click: this.refreshStats,
      },
    };
  }
}
